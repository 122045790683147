export enum ActionType {
    TIME_PERIOD_CHANGED = 'Time period changed',
    LOAD_EXPENSES = 'Load expenses and time report',
    SAVE_EXPENSE = 'Save expense',
    SAVE_EXPENSE_POPUP = 'Save expense pop-up',
    COPY_EXPENSE = 'Copy expense',
    IMPORT_EXPENSE_AMEX = 'Import expense from amex',
    DELETE_EXPENSE = 'Delete expense',
    SPLIT_EXPENSE = 'Split expense',
    LOAD_EXPENSES_CREATE_TRIP = 'Load expenses after create trip',
    SUBMIT_TIME_REPORT = 'Submit Time Report',
    SEND_FOR_APPROVAL = 'Send for approval',
    VALIDATE_TIME_REPORT_AND_SUBMIT = 'Validate for Submit or Send for Approval',
    COMPLETE_ADJUSTMENTS = 'Complete Adjustments',
    REMOVE_ADJUSTMENTS = 'Remove Adjustments',
    LOAD_TIME_REPORT = 'Load time report',
    RELOAD_TIME_REPORT = 'Reload time report',
    UPDATE_TIME_REPORT_STATUS = 'Update time report status',
    UPDATE_TIME_REPORT_CLEAR_TIMESHEET_EXPENSES = 'Update time report status, clear timesheet and expenses by date',
    UPDATE_TIME_REPORT_STATUS_REFRESH_EXPENSES = 'Update time report status and refresh expenses',
    LOAD_NEXT_EXPENSE_IMPORT_AMEX = 'Load next expense view for amex import',
    REVERSE_EXPENSE = 'Reverse expense',
    UPDATE_TRIP = 'Save trip',
    CREATE_TRIP = 'Create trip',
    APPLY_TRIP = 'Apply trip',
    DELETE_TRIP = 'Delete trip',
    LOAD_TIME_SHEET = 'Load TimeSheet',
    SAVE_TIME_SHEET = 'Save TimeSheet',
    CLEAR_TIME_SHEET = 'Clear time sheet and reload if needed',
    UPDATE_TIME_REPORT_STATUS_REFRESH_TIMESHEET = 'Update Time Report status and Refresh Timesheet',
    UPDATE_TIMESHEET = 'Update TimeSheet',
    UPDATE_AND_SAVE_TIMESHEET = 'Update and save TimeSheet',
    SAVE_WORK_SCHEDULE = 'Save WorkSchedule',
    UPDATE_STORES_BY_TAB = 'Update stores TimeSheet and Expenses by Tab',
    SELECT_SUBORDINATE = 'Select Subordinate',
    LOAD_PREFERENCES = 'Load preferences',
    SAVE_TIME_ENTRY_TEMPLATE = 'Save time entry template',
    SAVE_SHIFT_SCHEDULE = 'Save shift schedule',
    SAVE_SHIFT_SCHEDULE_CUSTOM_DAY = 'Save shift schedule custom day',
    SAVE_LOCATIONS = 'Save locations',
    DELETE_LOCATIONS = 'Delete location by day'
}

export const exceptionActionStoreGlobalSpinner: ActionType[] = [
    ActionType.SAVE_EXPENSE,
    ActionType.IMPORT_EXPENSE_AMEX,
    ActionType.DELETE_EXPENSE,
    ActionType.UPDATE_TRIP,
    ActionType.LOAD_EXPENSES_CREATE_TRIP,
    ActionType.APPLY_TRIP,
    ActionType.SAVE_EXPENSE_POPUP,
    ActionType.COPY_EXPENSE,
    ActionType.SAVE_TIME_SHEET,
    ActionType.LOAD_EXPENSES,
    ActionType.SAVE_WORK_SCHEDULE
]

export const exceptionActionStoreLoading: ActionType[] = [
    ActionType.TIME_PERIOD_CHANGED,
    ActionType.SPLIT_EXPENSE
]