import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalEventsService } from '@sharedServices/events/global-events.service';
import Subordinate from '@sharedModels/subordinates/subordinate';
import { LogService } from '@sharedServices/log/log.service';
import { GlobalCacheService } from '@sharedServices/cache/global-cache.service';
import { UserService } from '@sharedServices/user/user.service';
import { MyteBaseService } from '@sharedServices/myte-base.service';
import { ReportServiceController } from '@sharedServices/controller/report-service-controller';
import { AppConfigService } from '@authServices/app-config.service';
import { TimeReportStatusCode } from '@shared/models/time-report/time-report-status-code';
import { PintNotAvailable } from '@shared/utils/constants';
@Injectable({
    providedIn: 'root'
})
export class ReportService extends MyteBaseService {
    private appConfigData: any;
    private allowedStates = [
        TimeReportStatusCode.Submitted,
        TimeReportStatusCode.SubmittedAdjustment,
        TimeReportStatusCode.Processed,
        TimeReportStatusCode.ProcessedAdjustment
    ];

    constructor(
        public eventService: GlobalEventsService,
        public cacheService: GlobalCacheService,
        public userService: UserService,
        public logService: LogService,
        private appConfigService: AppConfigService,
        private http: HttpClient) {
            super(userService, cacheService, eventService, logService);
            this.appConfigData = this.appConfigService.getAppConfig;
    }

    public printPDF(isGroup: boolean, subordinates: Subordinate[], periodEnd: string): void {
        if (this.appConfigData.AdditionalConfig.enableNewEndpointPDF) {
            isGroup ? this.downloadTimeReportPdfForSubordinates(subordinates, periodEnd, this.appConfigData.PRINT_PDF_GROUP_URL) :
                        this.downloadTimeReportPdf(periodEnd, this.appConfigData.PRINT_PDF_URL);
        } else {
            isGroup ? this.downloadTimeReportPdfForSubordinates(subordinates, periodEnd, this.appConfigData.TIMEREPORT_URL + '/timereport-api') :
                        this.downloadTimeReportPdf(periodEnd, this.appConfigData.TIMEREPORT_URL + '/timereport-api');
        }
    }

    public downloadTimeReportPdf(endPeriod: string, apiURL: any): void {
        this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
        const url = ReportServiceController.generateURLforGetTimeReport(
            apiURL, this.userEid, endPeriod, this.isSubordinatedMode, this.supervisorEid, this.viewMode
        );
        this.http.get(url, { responseType: 'blob'}).subscribe({
            next: (res) => {
                this.openPdf(res);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
            },
            error: (error) => {
                if (error.status === 400) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const blobText = reader.result as string;
                        let errorMessage = JSON.parse(blobText);
                        this.logService.logError(errorMessage,true, PintNotAvailable);
                        this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                    };
                    reader.readAsText(error.error);
                }
                else{
                    this.logService.logError(error,true);
                    this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
                }
            }
        });
    }

    public downloadTimeReportPdfForSubordinates(subordinates: Subordinate[], endPeriod: string, apiURL: any): void {
        let idsList = ReportServiceController.getUsersToPrint(subordinates);
        if (idsList.length != 0) {
            this.globalEventsService.dispatchShowGlobalSpinnerEvent(true);
            const loginEid = this.supervisorEid == undefined ? this.userEid : this.supervisorEid;
            let url = ReportServiceController.generateURLforPrintAll(apiURL, endPeriod, loginEid, this.viewMode);
            this.http.post(url, idsList, { responseType: 'blob' }).subscribe(res => {
                this.openPdf(res);
                this.globalEventsService.dispatchShowGlobalSpinnerEvent(false);
            });
        } else {
            this.logService.logInfo('No processed time report to be printed', true, '');
        }
    }

    private openPdf(res: Blob): void {
        let file = new Blob([res], { type: 'application/pdf' });
        //NOTE: Real Browsers            
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank', 'resizable=1,toolbar=0,location=0,menubar=0');
        setTimeout(function () { URL.revokeObjectURL(fileURL) }, 10000);
    }
}
