import { TimeSheetCharge } from '../time-sheet-charge';

export default class TimeCategoryCharge implements TimeSheetCharge {
    public value: any;  
    public date: Date;    
    public tooltip: string;
    public hasError: boolean;
    public hasAdjustment: boolean;
    public isEditable : boolean;
    public isCustomDay : boolean;
    public errorType : string;
    public isLastDayOfWeekend?: boolean;
}