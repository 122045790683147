<div #chargeCodeList role="listbox" aria-labelledby="charcode-table" id="chargecode-getter-list" class="chargecode-list" cdkTrapFocus [cdkTrapFocusAutoCapture]="false">
    <div
        *ngFor="let chargecode of chargeCodes" 
        ngbTooltip=" {{ chargecode.tooltip }} "
        [disableTooltip]="!showTooltip || chargecode.isValid"
        [openDelay]="100" 
        container="body"
        class="chargecode" 
        (click)="select(chargecode)" 
        (keydown.Enter)="select(chargecode)"
        [ngClass]="{msacode: chargecode.masterServicesAgreement == 'X', isValid: !chargecode.isValid}" 
        tabindex="0" 
        role="option"
        (mouseenter)="showTooltip = true"
        (mouseleave)="showTooltip = false">
        {{ chargecode.displayName }}
    </div>
</div>
