import { Injectable } from '@angular/core';
import AuditInquiry from '@shared/models/expense/audit/expense-audit-inquiry';
import TimeReport, { TimeReportStatusModel } from '@shared/models/time-report/time-report';
import TimeReportSettings from '@shared/models/time-report/time-report-settings';
import TimeSheet from '@sharedModels/time-sheet/time-sheet';
import { TimePeriodPipe } from '@shared/pipes/time-period.pipe';
import { GlobalCacheService } from '@shared/services/cache/global-cache.service';
import { TimeReportService } from '@shared/services/time-report/time-report.service';
import { BehaviorSubject, Observable, map, of, switchMap, tap } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ExpenseAuditService } from '../../../myte-expenses/shared/services/audit/expense-audit.service';
import { WorkLocationDayInfo } from '@shared/clients/locations-api-client';
import { LocationsService } from '../../../myte-locations/shared/services/locations/locations.service';
import { TimeSheetService } from '../../../myte-time/shared/services/timesheet/timesheet.service';
import { Timesheet } from '@shared/clients/time-legacy-api-client';
import { LocationEntry, LocationsByDay } from '@shared/models/locations/locations-by-day';
import { WorkScheduleGroup } from '@shared/models/time-sheet/work-schedule/time-sheet-work-schedule';
import { SaveWorkScheduleOutput, ScheduleSetting, SettingConstraint } from '@shared/clients/time-api-client';
import { ChargecodesService } from '../../../myte-chargecodes/shared/services/chargecodes.service';
import { TimeEntryTemplate } from '../../../shared/models/time-sheet/time-entry/time-entry-template';
import { TimeEntryTemplateService } from '../../../myte-popups/shared/services/time-entry-template.service';
import TimeEntryTemplateMapper from '../../../myte-popups/shared/services/time-entry-template.mapper';
import { TimeEntryTemplateResponse } from './shared/time-entry-template-response';
import { ShiftSchedule } from '@shared/models/time-sheet/shift-schedule/shift-schedule';
import { ChargeCodes } from '@shared/models/charge-code/charge-codes.model';
import { TimeCategoryTask } from '@shared/models/time-sheet/time-category/time-category-task';
import { TimeReportStatus, TimeReportSubmitMode, ValidationErrorOutPut } from '@shared/clients/timereport-api-client';
import { SubordinatesMode } from '@shared/models/subordinates/subordinate-mode';
import { TimeReportValidationErrors } from '@shared/models/time-report/time-report-validation-errors';

export interface timeGridInformation {
    timeSheet: TimeSheet,
    workLocations: WorkLocationDayInfo[]
}

@Injectable({
    providedIn: 'root'
})
export class TimeSheetStoreService {
    private timeSheet: BehaviorSubject<TimeSheet> = new BehaviorSubject(new TimeSheet());
    private workLocations: BehaviorSubject<WorkLocationDayInfo[]> = new BehaviorSubject(new Array<WorkLocationDayInfo>());
    private timeGridData: BehaviorSubject<timeGridInformation> = new BehaviorSubject(null);
    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private periodEnd: BehaviorSubject<Date> = new BehaviorSubject(new Date((new TimePeriodPipe).transform(new Date())));

    public readonly timeSheet$: Observable<TimeSheet> = this.timeSheet.asObservable();
    public readonly workLocations$: Observable<WorkLocationDayInfo[]> = this.workLocations.asObservable();
    public readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();
    public readonly periodEnd$: Observable<Date> = this.periodEnd.asObservable();
    public readonly timeGridData$: Observable<timeGridInformation> = this.timeGridData.asObservable();

    constructor(
        public locationsService: LocationsService,
        public globalCacheService: GlobalCacheService,
        public timeService: TimeSheetService,
        public chargeCodesService: ChargecodesService,
        public timeEntryTemplateService: TimeEntryTemplateService,
        public timeReportService: TimeReportService) { }


    public loadTimeSheet(periodEnd: Date, shouldInitialize: boolean = false, isSubmit: boolean = false) {
        if (shouldInitialize)
            this.resetTimeSheetAndLocations(periodEnd);
        return forkJoin([
                    this.timeService.getTimeSheetStore(periodEnd, isSubmit),
                    this.locationsService.getWorkLocations()
                ])
                .pipe(tap(([timeSheet, workLocations]: [TimeSheet, WorkLocationDayInfo[]]) => {
      
                    this.timeGridData.next({

                        timeSheet: timeSheet,
                        workLocations: workLocations
                    });
                    this.timeSheet.next(timeSheet);
                    this.workLocations.next(workLocations);
                    this.handleTimeSheetCache(periodEnd);
                }));
    }
    public updateTimeSheet(periodEnd: Date, timeSheet: TimeSheet): void {
        this.timeGridData.next({
            timeSheet: timeSheet,
            workLocations: this.workLocations.getValue()
        });
        this.timeSheet.next(timeSheet);
        this.handleTimeSheetCache(periodEnd);
    }

    public updateAndSaveTimeSheet(periodEnd: Date, timeSheet: TimeSheet, toastMessage: string = null): Observable<[TimeSheet, WorkLocationDayInfo[]]> {
        return this.timeService.saveTimeSheet(timeSheet, periodEnd, toastMessage)
                .pipe(switchMap(saveResponse => this.loadTimeSheet(periodEnd, false)));
    }

    private resetTimeSheetAndLocations(periodEnd: Date): void {
        this.globalCacheService.clearTimeSheetFromBuffer(periodEnd);
        this.globalCacheService.resetWorkLocationList();
    }

    public resetTimeSheetCache(): void {
        this.globalCacheService.resetTimeSheet();
    }

    private handleTimeSheetCache(periodEnd: Date) {
        this.globalCacheService.setTimeSheet(of(this.timeSheet.value), periodEnd, false);
    }

    public saveTimeSheet(periodEnd: Date, toastMessage: string): Observable<TimeSheet> {
        return this.timeService.saveTimeSheet(this.timeSheet.value, periodEnd, toastMessage)
                .pipe(tap(timeSheetSaveResponse => {
                    this.timeSheet.next(timeSheetSaveResponse);
                    this.timeGridData.next({
                        timeSheet: timeSheetSaveResponse,
                        workLocations: this.workLocations.getValue()
                    });
                }));
    }

    public saveTimeSheetIfNeeded(periodEnd: Date) {
        if (this.timeSheet.getValue().modified || this.timeSheet.getValue().shouldResave) {
            return this.saveTimeSheet(periodEnd, null);
        } else {
            return of(null);
        }
    }

    public saveTimeSheetIfNeededForSubmit(periodEnd: Date) {
        if (!this.timeSheet.getValue().isSaved || this.timeSheet.getValue().modified || this.timeSheet.getValue().shouldResave) {
            return this.saveTimeSheet(periodEnd, null);
        } else {
            return of(null);
        }
    }

    public isWorkScheduleComplete(): boolean {
        let workSchedule: TimeCategoryTask = this.timeSheet.getValue().timeCategory.tasks.find(ws => ws.name == 'Work Schedule');
        if (workSchedule != undefined && workSchedule.totalHours == '.0') {
            workSchedule.charges.forEach(wsCell => {
                wsCell.hasError = true;
            });
            return false;
        } else {
            return true;
        }
    }

    public shouldSaveTimeSheetAndLocations(subordinateMode: SubordinatesMode): boolean {
        return ((this.timeSheet.getValue().modified ||
                [TimeReportStatusModel.New, TimeReportStatusModel.Draft, TimeReportStatusModel.SubmittedPendingApproval, TimeReportStatusModel.DraftPendingUpdates].includes(TimeReportStatusModel[this.timeSheet.getValue().timeReportStatus]))
                && subordinateMode != SubordinatesMode.Approver);
    }

    public saveLocationsIfNeeded(periodEnd: Date, timeReportStatus: string, ignoreTimeReportStatus: boolean = false): Observable<LocationsByDay> {
        return this.globalCacheService.getLocationsByDay(periodEnd)
                .pipe(switchMap(locationByDay => {
                    if (ignoreTimeReportStatus) {
                        return locationByDay ? this.locationsService.saveLocations(locationByDay, true) : of(null);
                    } else {
                        return locationByDay && timeReportStatus != TimeReportStatusModel.SubmitPendingAdjustment ? this.locationsService.saveLocations(locationByDay, true) : of(null);
                    }
                }));
    }

    public saveLocations(periodEnd: Date, locationsByDay: LocationsByDay): Observable<LocationsByDay> {
        return this.locationsService.saveLocations(locationsByDay)
                .pipe(tap(locationsSaveResponse => this.resetTimeSheetAndLocations(periodEnd)))
    }

    public deleteLocations(periodEnd: Date, locationsByDay: LocationsByDay, locationEntry: LocationEntry): Observable<LocationsByDay> {
        return this.locationsService.deleteLocation(locationsByDay, locationEntry)
                .pipe(tap(locationsDeleteResponse => this.resetTimeSheetAndLocations(periodEnd)))
    }

    public saveWorkSchedule(periodEnd: Date, workScheduleGroup: WorkScheduleGroup): Observable<SaveWorkScheduleOutput> {
        return this.timeService.saveWorkScheduleStore(workScheduleGroup, periodEnd)
                .pipe(tap(workScheduleResponse => {
                    this.globalCacheService.resetTimeSheetForTimeReportsNew(); // to-do: investigate this.
                }));
    }

    public updateIsDismissChecked(chargeCodeRelated: string, dismissCheckboxValue: boolean, periodEnd: Date): Observable<boolean> {
        return this.chargeCodesService.updateIsDismissCheckedValueStore(chargeCodeRelated, dismissCheckboxValue, periodEnd)
                .pipe(tap(dismissResponse => {
                    this.globalCacheService.resetChargeCodes();
                    this.resetTimeSheetAndLocations(periodEnd);
                    return dismissResponse;
                }));
    }

    public deleteChargeCode(cdeleteChargeCodes: string[], periodEnd: Date): Observable<ChargeCodes> {
        return this.chargeCodesService.deleteChargeCodeStore(cdeleteChargeCodes)
                .pipe(tap(dismissResponse => {
                    this.resetTimeSheetAndLocations(periodEnd);
                    return dismissResponse;
                }));
    }

    public saveTimeEntryTemplate(timeEntryTemplate: TimeEntryTemplate, periodEnd: Date, isTimeTab: boolean): Observable<TimeEntryTemplateResponse> {
        return this.timeEntryTemplateService.saveTimeEntryTemplateStore(timeEntryTemplate, periodEnd)
                .pipe(switchMap(timeEntrySaveResponse => {
                    return forkJoin([
                        timeEntrySaveResponse.shouldUseTimeEntryTemplate && isTimeTab ? this.loadTimeSheet(periodEnd, true) : of(this.resetTimeSheetAndLocations(periodEnd)),
                        of(timeEntrySaveResponse)
                    ])
                }))
                .pipe(map(([timeSheetResponse, timeEntrySaveResponse]) => {
                    let timeEntryTemplateResponse = new TimeEntryTemplateResponse();
                    timeEntryTemplateResponse.shouldUseTimeEntryTemplate = timeEntrySaveResponse.shouldUseTimeEntryTemplate;
                    timeEntryTemplateResponse.hasErrorSave = timeEntrySaveResponse.tasks.some(task => task.assignment.hasError);
                    timeEntryTemplateResponse.timeEntryTemplate = TimeEntryTemplateMapper.mapTimeEntryTemplateAfterSave(timeEntryTemplate, TimeEntryTemplateMapper.mapTimeEntryTemplate(timeEntrySaveResponse));
                    return timeEntryTemplateResponse;
                }));
    }

    public saveShiftSchedule(shiftSchedule: ShiftSchedule, periodEnd: Date, timePeriodDates: Date[]): Observable<ShiftSchedule> {
        const shouldSaveTimeSheet = this.timeSheet.getValue().isEditable && this.timeSheet.getValue().modified && this.timeSheet.getValue().timeReportStatus == TimeReportStatusModel.New;
        const observableSaveTimeSheet: Observable<TimeSheet> = shouldSaveTimeSheet ? this.saveTimeSheet(periodEnd, null) : of(this.timeSheet.getValue());
        return observableSaveTimeSheet
                .pipe(switchMap(timeSheetSaveResponse => {
                    return this.timeService.saveShiftScheduleStore(shiftSchedule, periodEnd, timePeriodDates)
                }))
                .pipe(switchMap(shiftScheduleResponse => {
                    return forkJoin([
                        of(shiftScheduleResponse),
                        shiftScheduleResponse.error ? of(this.resetTimeSheetAndLocations(periodEnd)) : this.loadTimeSheet(periodEnd, true)
                    ])
                }))
                .pipe(switchMap(([shiftScheduleResponse, timeSheetResponse]) => {
                    return of(shiftScheduleResponse);
                }));
    }

    public saveShiftScheduleCustomDay(shiftType: string, periodEnd: Date, date: Date): Observable<ShiftSchedule> {
        return this.timeService.saveShiftScheduleCustomDayStore(shiftType, periodEnd, date)
                .pipe(switchMap(shiftScheduleCustomDayResponse => {
                    return forkJoin([
                        of(shiftScheduleCustomDayResponse),
                        shiftScheduleCustomDayResponse.error ? of(this.resetTimeSheetAndLocations(periodEnd)) : this.loadTimeSheet(periodEnd, true)
                    ])
                }))
                .pipe(switchMap(([shiftScheduleCustomDayResponse, timeSheetResponse]) => {
                    return of(shiftScheduleCustomDayResponse);
                }));
    }

    public loadTimeSheetForSubmission(periodEnd: Date, submissionMode: TimeReportSubmitMode, validationError: ValidationErrorOutPut[]) {
        return this.timeService.getTimeSheetForSubmission(periodEnd, submissionMode, validationError)
                .pipe(tap(timeSheetResponse => {
                    this.updateTimeSheet(periodEnd, timeSheetResponse);
                }));
    }
}
