import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root",
})
export class ApprovalOvertimeAndAllowanceConstantService {

    public approvalAllowanceGrid = {
        popupParentDomElement: "body",
        pendingApproval: "Pending Approval",
        approved: "Approved",
        notApproved: "Not Approved",
        approverComments: "ApproverComments",
    };

    public approvalGaurd = {
        undefined: "undefined",
        notFound: "not-found",
    };

    public countryKeyForCJ = 'CJ'
    public countryKeyForMY = 'MY'

    public myteApprovalOvertimeAllowance = {
        title: "Overtime Approval",
        approve: "Approve",
        termsOfUse: "Terms of Use",
        privacyStatement: "Privacy Statement",
        success: "Success",
        reject: "Reject",
      };
       
    public myteApprovalOvertimeAndAllowanceGrid = {
        selectionCheckbox: "ag-selection-checkbox",
        hidden: "ag-hidden",
    };

    public myteApprovalOvertimeAndAllowanceControls = {
        timeReportPeriod: "TR Period",
        search: "Search",
        processedPeriod: "processedPeriod",
        selectAll: "Select All",
        pendingApproval: "Pending Approval",
        approved: "Approved",
        rejected: "Not Approved",
        overtime: "Overtime",
        error: "Error",
        peoplePickerLimit:
            "The number of Enterprise IDs in this field must not exceed 20.",
        processedPeriodInput: "processedPeriod_input",
        approvalStatus: "Approval Status",
        notApproved: "Not Approved",
        overtimeSuccessApproveMessage: 'The overtime(s) was approved.',
        overtimeSuccessRejectMessage: 'The overtime(s) was not approved.',
        allowanceInvalidDateMessage: 'Unable to get your Allowance Requests during Invalid Date period. If the problem persists, please escalate the issue at myTimeandExpenses help.',
        overtimeApprovalStatusError: 'Unable to process the Allowance Requests selected.If the problem persists please escalate the issue at myTimeandExpenses Help.',
        msgBlankValueValidationForRejectComment: 'Enter the reason for rejection in the Comment field.',
        invalidDate: 'Invalid Date'
    };


    public myteApprovalOvertimeAndAllowanceService = {
        getOvertimeRequestConfError: `Unable to get your Overtime Approval Configuration.
      If the problem persists please escalate the issue at myTimeandExpenses Help.`
    }

    public approvalStatusList = [
        this.myteApprovalOvertimeAndAllowanceControls.selectAll,
        this.myteApprovalOvertimeAndAllowanceControls.pendingApproval,
        this.myteApprovalOvertimeAndAllowanceControls.approved,
        this.myteApprovalOvertimeAndAllowanceControls.notApproved,
    ];

}

export enum PeoplePickerButtonValue {
    Hide = 'Hide All Employee(s)',
    Show = 'See All Employee(s)',
  }



