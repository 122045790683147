import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { ExpenseHistory } from '../../../../shared/models/expense/expense-history.model';
import {
    ExpenseApiClient,
    ExpenseListInput,
    ExpenseInput,
    ImportAmexInput,
    ExpenseImportInput,
    DeleteAmexExpenseInput,
    ExpenseReverseInput,
    ExpenseSplitOutput,
    ExpenseSplitInput,
    DeleteExpenseImport,
    ExpenseHistory as APIExpenseHistory,
    TimeReportSubmitMode,
    MyteTabControlOutput,
    ValidationError
} from '../../../../shared/clients/expense-api-client';
import { GlobalCacheService } from '../../../../shared/services/cache/global-cache.service';
import { ExpenseReceipts } from '../../../../shared/models/expense/receipt/expense-receipt';
import { ReceiptsService } from '../../../../shared/services/receipts/receipts.service';
import ExpenseView from '../../../../shared/models/expense/expense-view';
import Expense from '../../../../shared/models/expense/expense';
import DeleteAmexExpenseInfo from '../../../../shared/models/expense/amex/expense-amex-delete-info';
import ReceiptViewField from '../../../../shared/models/expense/field/expense-receipt-view-field';
import AmexInput from '../../../../shared/models/expense/amex/expense-amex-import';
import ExpenseViewRowElement from '../../../../shared/models/expense/field/expense-view-row-element';
import ExpenseServiceMapper from './expense.service.mapper';
import { ExpenseViewSource } from '../../../../shared/models/expense/expense-view-source';
import { Router } from '@angular/router';
import { LogService } from '../../../../shared/services/log/log.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { AsyncSubject } from 'rxjs';
import DeleteCorporateCardInfo from '../../../../shared/models/expense/amex/expense-corporate-card-delete';
import CorporateCardExpenseInput from '../../../../shared/models/expense/amex/expense-corporate-card-import';
import { AppConfigService } from '../../../../auth/services/app-config.service';
import { MyteBaseService } from '@shared/services/myte-base.service';
import { GlobalEventsService } from '../../../../shared/services/events/global-events.service';
import { ExpenseViewFieldValidationError } from '../../../../shared/models/expense/expense-view-field-validation-error';
import { TimeReportUtils } from '../../../../shared/utils/timeReport.utils';
import { Subject } from 'rxjs';
import { PeriodEndService } from '../../../../shared/services/periodend/periodend.service';
import { ExpensesResponse, SaveExpenseResponse } from '@shared/services/store/expense-store.service';
import DropdownItem from '@shared/models/controls/dropdown/dropdown-item';
import { ExpenseHistories } from '@shared/models/expense/expense-histories.model';
import { MyteTabControl } from '@shared/models/myte-tab-control.model';
import { SubordinatesServiceMapper } from '@shared/services/subordinates/subordinates.service.mapper';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ExpenseService extends MyteBaseService {
    public expenseFileChange = new AsyncSubject<string>();
    public isReadyForSave = false;
    public useCorporateCard: boolean;
    private appConfigData: any;
    public dictionaryTabs = new Map<string, boolean>();
    private isFromExpensePopupCloseWithoutAmex: boolean = false;
    public saveChange: Subject<boolean> = new Subject<boolean>();
    public checkboxlist: string[] = ['T2_Accompanied'];
    public expenses: Expense[] = [];

    constructor(private api: ExpenseApiClient,
        private receiptService: ReceiptsService,
        private router: Router,
        private appConfigService: AppConfigService,
        logService: LogService,
        userService: UserService,
        globalCacheService: GlobalCacheService,
        globalEventsService: GlobalEventsService,
        private periodEndService: PeriodEndService) {
        super(userService, globalCacheService, globalEventsService, logService);
        this.appConfigData = this.appConfigService.getAppConfig;
        this.useCorporateCard = this.appConfigData.AdditionalConfig.useCorporateCard;
    }

    public getExpenseSplit(expenseId: string): Observable<ExpenseSplitOutput[]> {
        return this.api.expenseSplit(expenseId, this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode)
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    public getExpenseHistoryByExpenseIdObservable(expenseId: string): Observable<ExpenseHistories> {
        return this.api.getExpenseHistoryByExpenseId(expenseId, this.periodEndService.getActivePeriod(), this.userEid, this.supervisorEid, this.viewMode)
            .pipe(map((expenseHistory: Array<APIExpenseHistory>) => {
                return ExpenseService.mapAPIExpenseHistories(expenseHistory);
            }))
            .pipe(catchError((error: any) => {
                this.logService.logError(error, true);
                return of(undefined);
            }));
    }

    public static mapAPIExpenseHistories(apiExpenseHistories: Array<APIExpenseHistory>): ExpenseHistories {
        const expenseHistories: ExpenseHistories = new ExpenseHistories();
        if (apiExpenseHistories && Object.keys(apiExpenseHistories).length > 0) {
            expenseHistories.expenseHistory = this.mapAPIExpenseHistory(apiExpenseHistories);
        }
        return expenseHistories;
    }

    private static mapAPIExpenseHistory(apiExpenseHistories: Array<APIExpenseHistory>): Array<ExpenseHistory> {
        const expenseHistories: Array<ExpenseHistory> = new Array<ExpenseHistory>();
        if (apiExpenseHistories && apiExpenseHistories.length > 0) {
            apiExpenseHistories.map((apiExpenseHistory: APIExpenseHistory) => {
                const expenseHistory: ExpenseHistory = new ExpenseHistory();
                if (apiExpenseHistory && Object.keys(apiExpenseHistory).length > 0) {
                    Object.assign(expenseHistory, apiExpenseHistory);
                    expenseHistory.approver = apiExpenseHistory.approver;
                    expenseHistory.date = moment(new Date(apiExpenseHistory.date)).isValid() ? moment(new Date(apiExpenseHistory.date)).toDate() : undefined;
                    expenseHistory.time = apiExpenseHistory.time;
                    expenseHistory.comments = apiExpenseHistory.comments;
                    expenseHistory.status = apiExpenseHistory.status;
                }
                expenseHistories.push(expenseHistory);
            });
        }
        return expenseHistories;
    }

    public getHistoryExpense(expenseId: string): Observable<ExpenseHistory[]> {
        return this.api.getExpenseHistoryByExpenseId(expenseId, this.periodEndService.getActivePeriod(), this.userEid, this.supervisorEid, this.viewMode)
            .pipe(map(response => {
                let expenseHistory: ExpenseHistory[] = new Array(response.length);
                for (let i = 0; i < response.length; i++) {
                    expenseHistory[i] = new ExpenseHistory();
                    expenseHistory[i].approver = response[i]?.approver;
                    expenseHistory[i].comments = response[i]?.comments;
                    expenseHistory[i].date = new Date(response[i]?.date);
                    expenseHistory[i].status = response[i]?.status;
                    expenseHistory[i].time = response[i]?.time;
                }
                return expenseHistory;
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    public saveSplit(expenseId: string, expenseCharges: ExpenseSplitOutput[], countryKey: string): Observable<ValidationError> {
        let saveExpenseSplit = new ExpenseSplitInput();
        saveExpenseSplit.expenseId = expenseId;
        saveExpenseSplit.expenseCharges = ExpenseServiceMapper.mapExpenseChargesAjd(expenseCharges);

        return this.api.saveExpenseSplit(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, saveExpenseSplit)
            .pipe(map(expenseSplit => {
                let periodEnd = this.globalCacheService.getPeriodEnd();
                this.globalCacheService.handleResetExpenses(periodEnd, countryKey);
                return ExpenseServiceMapper.mapExpenseSplitList(expenseSplit);
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    //US-1186657: In 'DraftAdjustment' status clear timesheet cache only if the user change the homeOfficeLocation from IL to Non-IL or vise-versa.
    private clearTimesheetCacheForIllinois(enterpriseId: string, status: string) {
        if (this.globalCacheService.getUserCountry() !== 'US' || status !== 'DraftAdjustment') {
            return;
        }
        let isIllinois = false;
        let isILMealBreak = false;
        this.globalCacheService.getUserPreferences(enterpriseId, this.globalCacheService.getPeriodEnd()).subscribe(preference => {
            if (preference.homeOfficeLocation.currentHomeOfficeLocationStateCode === '14') {
                isIllinois = true; //homeOfficeLocation == Illinois
            }
        });

        this.globalCacheService.getTimeSheet(this.globalCacheService.getPeriodEnd()).subscribe(timesheet => {
            timesheet.timeCategory.tasks.forEach(task => {
                if (task.code === 'R86' || task.code === 'R87') {
                    isILMealBreak = true; //RTC data is present
                }
            });
        })

        if (isIllinois || isILMealBreak) {
            this.globalCacheService.clearTimeSheetFromBuffer(this.globalCacheService.getPeriodEnd());
        }
    }
    private initializeTabsDictionary(expenses: Expense[]): void {
        if (!this.dictionaryTabs || expenses === null) return;
        const errors = expenses.find(expense => expense.assignment.isValid === false || expense.hasFederalWBSInd && expense.frequentTrip.id == null
            || expense.taxReimbursableBool && !expense.expenseAmount.taxTrueUpValue);
        if (errors) {
            this.dictionaryTabs.set('Expenses', true);
        } else {
            this.dictionaryTabs.set('Expenses', false);
        }
    }

    /** Use this method to obtain the full information of a Expense given an id
     * @param expenseId The Id of the requested Expense
     * @returns ExpenseView
     */
    public getExpense(expenseId: string, countryKey: string): Observable<ExpenseView> {
        let expenseInput = new ExpenseInput();
        let expense = this.expenses.find(expense => expense.id === expenseId);
        expenseInput.enterpriseId = this.userEid;
        expenseInput.expenseId = expenseId;
        expenseInput.fields = [];
        expenseInput.recalculate = false;
        expenseInput.countryKey = countryKey;
        expenseInput.periodEnd = this.globalCacheService.getPeriodEnd();
        expenseInput.hasIssues = expense?.hasissues;
        expenseInput.sequenceNm = Number(expense?.sequence.value);

        return forkJoin([
            this.api.expenseDetails(this.supervisorEid, this.viewMode, expenseInput),
            this.receiptService.getReceipts(expenseId, false)
        ])
            .pipe(map(data => {
                return ExpenseServiceMapper.mapExpenseView(data[0], data[1]);
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                this.router.navigate(['/expenses/']);
                return of(null);
            }));
    }

    public getExpenseForCopy(expenseId: string, countryKey: string): Observable<ExpenseView> {
        let expenseInput = new ExpenseInput();
        expenseInput.enterpriseId = this.userEid;
        expenseInput.expenseId = expenseId;
        expenseInput.fields = [];
        expenseInput.countryKey = countryKey;
        expenseInput.periodEnd = this.globalCacheService.getPeriodEnd();
        expenseInput.triggeredSource = ExpenseViewSource.Copy;

        return forkJoin([
            this.api.expenseDetails(this.supervisorEid, this.viewMode, expenseInput)

        ])
            .pipe(map(data => {
                return ExpenseServiceMapper.mapExpenseViewForCopy(data[0])
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    /** Use this method to recalculate the expense
     * @param expense The edited expense
     * @param triggeredBy The field that trigger the expense change.
     * @returns Another ExpenseView entity with the fields recaluclated
     */
    public recalculateExpense(expenseView: ExpenseView, triggeredBy: ExpenseViewRowElement, countryKey: string): Observable<ExpenseView> {
        let expenseInput = new ExpenseInput();
        expenseInput.enterpriseId = this.userEid;
        expenseInput.periodEnd = expenseView.periodEnd;
        expenseInput.expenseId = expenseView.id;
        expenseInput.expenseTypeCd = expenseView.code;
        expenseInput.recalculate = true;
        expenseInput.triggeredBy = triggeredBy.key;
        expenseInput.fields = ExpenseServiceMapper.mapExpenseViewToFileInput(expenseView);
        expenseInput.mealsClaimed = ExpenseServiceMapper.mapMealsClaimedToInput(expenseView);
        expenseInput.giftEntryDto = ExpenseServiceMapper.mapGiftToInput(expenseView);
        expenseInput.countryKey = countryKey;
        expenseInput.amexInput = expenseView.amexInfo
            ? ExpenseServiceMapper.mapSaveAmexInput(expenseView.amexInfo)
            : null;
        expenseInput.expenseImportInput = expenseView.importInfo
            ? this.useCorporateCard && ExpenseServiceMapper.mapSaveCorporateCardInput(expenseView.importInfo)
            : null;
        expenseInput.triggeredSource = expenseView.expenseSource;
        let receiptView = expenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .find(expenseViewFieldRow => expenseViewFieldRow instanceof ReceiptViewField) as ReceiptViewField;

        let expenseReceipt = new ExpenseReceipts();
        if (receiptView != null) {
            expenseReceipt.receipts = receiptView.value;
            expenseReceipt.warningMessages = receiptView.warningMessages;
            expenseReceipt.missingReceiptInfo = receiptView.missingReceiptInfo;
        }

        return this.api.expenseDetails(this.supervisorEid, this.viewMode, expenseInput)
            .pipe(map(expenseOutput => ExpenseServiceMapper.mapExpenseView(expenseOutput, expenseReceipt)))
            .pipe(map(newExpenseView => ExpenseServiceMapper.joinRecalculatedExpense(newExpenseView, expenseView)))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    /** Use this method to request a new Expense based on a expense type code
     * @param expenseTypeCode The Expense type
     * @returns a new entity of ExpenseView
     */
    public newExpense(periodEnd: Date, expenseTypeCode: string, countryKey: string): Observable<ExpenseView> {
        let input = new ExpenseInput;
        input.enterpriseId = this.userEid;
        input.expenseId = null;
        input.expenseTypeCd = expenseTypeCode;
        input.periodEnd = periodEnd;
        input.recalculate = true;
        input.countryKey = countryKey;

        return this.api.expenseDetails(this.supervisorEid, this.viewMode, input)
            .pipe(map(expenseOutput => {
                return ExpenseServiceMapper.mapExpenseView(expenseOutput);
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }
    public getErrorDisplayName(error: ExpenseViewFieldValidationError[], errorDisplayName: Array<any>) {
        if (error != null) {
            error.forEach(e => {
                if ((e.type == 'BlockSaveExpenseError' && e.errorMessage != null && e.label != null && e.label != '')
                    || (e.type == 'required' && e.errorMessage != null && e.label != null && e.label != '')
                    || (e.type == 'BlockError' && e.errorMessage != null && e.label != null && e.label != '')) {
                    if (!errorDisplayName.some(err => err.label == e.label)) {
                        errorDisplayName.push({
                            label: e.label,
                            type: e.type
                        });
                    }
                }
            });
        }
    }
    public validationSaveExpense(expenseView: ExpenseView): boolean {
        let isValid = true;
        let hasRequiredError = false;
        let errorDisplayName = new Array<any>();
        expenseView.categories.forEach(category =>
            category.forEach(row =>
                row.elements.forEach(f => {
                    this.getErrorDisplayName(f.validate(f.value, true) ? f.validate(f.value, true) : null, errorDisplayName);
                    if (!f.isValid() || f.value === 'LINE') {
                        isValid = false;
                        hasRequiredError = hasRequiredError || f.hasRequiredError();
                    }
                })
            )
        );

        if (!isValid) {
            if (errorDisplayName.length > 0 && !hasRequiredError) {
                let fields = new Array<any>();
                if (errorDisplayName.length === 1) {
                    this.logService.logWarning('Please correct the following fields: ' + errorDisplayName[0].label + '.', true, 'The Expense is not ready to save.');
                }
                if (errorDisplayName.length >= 2) {
                    errorDisplayName.forEach(element => {
                        if (element.type != 'required') {
                            fields.push(element.label);
                        }
                    });
                    this.logService.logWarning('Please correct the following fields: ' + fields.join(", ").replace(/, ([^,]*)$/, ' and $1') + '.', true, 'The Expense is not ready to save.');
                }
            } else if (hasRequiredError) {
                let fields = new Array<any>();
                if (errorDisplayName.length === 1) {
                    this.logService.logWarning('Please complete: ' + errorDisplayName[0].label + ' field.', true, 'The Expense is not ready to save.');
                }
                if (errorDisplayName.length >= 2) {
                    errorDisplayName.forEach(element => {
                        if (element.type == 'required') {
                            fields.push(element.label);
                        }
                    });
                    this.logService.logWarning('Please complete: ' + fields.join(", ").replace(/, ([^,]*)$/, ' and $1') + ' fields.', true, 'The Expense is not ready to save.');
                }
            } else {
                console.log("mostrando", errorDisplayName);
                this.logService.logWarning('Please modify invalid fields', true, 'The Expense is not valid.');
            }
        }
        return isValid;
    }

    // TODO (m.fernandez.bortolas): Change the return type.
    public saveExpense(expenseView: ExpenseView, countryKey: string): Observable<any> {
        let receiptView = expenseView.categories.map(expenseRow => expenseRow)
            .reduce((prev, curr) => prev.concat(curr))
            .map(expenseViewRow => expenseViewRow.elements)
            .reduce((prev, curr) => prev.concat(curr))
            .find(expenseViewFieldRow => expenseViewFieldRow instanceof ReceiptViewField) as ReceiptViewField;

        let showErrorReceiptUpload = true;

        if (this.validationSaveExpense(expenseView) && !receiptView.isMissingReceiptInvalid()) {
            let expenseInput: ExpenseInput = new ExpenseInput();
            expenseInput.enterpriseId = this.userEid;
            expenseInput.expenseId = expenseView.id;
            expenseInput.expenseTypeCd = expenseView.code;
            expenseInput.periodEnd = expenseView.periodEnd;
            expenseInput.recalculate = true;
            expenseInput.fields = ExpenseServiceMapper.mapExpenseViewToFileInput(expenseView);
            expenseInput.mealsClaimed = ExpenseServiceMapper.mapMealsClaimedToInput(expenseView);
            expenseInput.giftEntryDto = ExpenseServiceMapper.mapGiftToInput(expenseView);
            expenseInput.isNotHotelFolioOrIsTheOnlyHotelFolio = expenseView.amexInfo
                ? expenseView.amexInfo.shouldImportWithTransaction
                : null;
            expenseInput.amexInput = expenseView.amexInfo
                ? ExpenseServiceMapper.mapSaveAmexInput(expenseView.amexInfo)
                : null;
            expenseInput.expenseImportInput = expenseView.importInfo
                ? this.useCorporateCard && ExpenseServiceMapper.mapSaveCorporateCardInput(expenseView.importInfo)
                : null;
            expenseInput.countryKey = countryKey;

            if (expenseView.softWarning)
                expenseInput.maximunAmountWarningConfirmation = expenseView.softWarning.userConfirmation;

            expenseInput.receipts = ExpenseServiceMapper.mapReceiptsToReceiptInput(expenseView, this.userEid);


            let expenseReceipt = new ExpenseReceipts();
            if (receiptView != null) {
                expenseReceipt.receipts = receiptView.value;
                expenseReceipt.warningMessages = receiptView.warningMessages;
                expenseReceipt.missingReceiptInfo = receiptView.missingReceiptInfo;
            }

            expenseInput.missingReceiptInfo = receiptView.missingReceiptInfo;

            let saveExpenseRequest = this.api.saveExpense(this.supervisorEid, this.viewMode, expenseInput)
                .pipe(map(expenseOutput => {
                    let toastMsg = [];
                    expenseOutput?.fields?.forEach(f => {
                        f?.validations?.forEach(v => {
                            if (v && v.type == 'saveValiatorError'|| v.type == 'BlockError') {
                                if (toastMsg.indexOf(v.value) === -1) {
                                    toastMsg.push(v.value);
                                }
                            }
                        })
                    });
                    if (toastMsg && toastMsg.length > 0) toastMsg.forEach(message => this.logService.logError(message, true, ""));
                    expenseOutput?.messages?.forEach(error => {
                        if (error?.text?.indexOf("The photo image is not clear.") != -1) {
                            this.logService.logError(`Unable to upload ${error?.type}. ${error?.text}`, true, 'Error On Saving Expense');
                        }
                        if (error?.text?.indexOf("MyTime&Expenses is experiencing issues with receipt uploads. Please try again later. If you continue to experience this issue, please contact Technology Support.") != -1) {
                            if (showErrorReceiptUpload) {
                                this.logService.logError(`${error?.text}`, true, 'No Receipt Attached');
                                showErrorReceiptUpload = false;
                            }
                        }
                    });
                    return ExpenseServiceMapper.mapExpenseView(expenseOutput, expenseReceipt);
                })).pipe(catchError(err => {
                    this.logService.logError(err, true, 'Error On Saving Expense');
                    return of(null);
                }));

            return this.globalCacheService.handleSaveExpense(expenseInput.periodEnd, expenseInput.enterpriseId, saveExpenseRequest, countryKey, expenseInput.expenseTypeCd);
        } else {
            return of(null);
        }
    }

    public copyExpense(expenseView: ExpenseView, countryKey: string): Observable<any> {
        let isValid = true;
        let errorDisplayName = new Array<any>();
        expenseView.categories
            .forEach(category =>
                category.forEach(row =>
                    row.elements.forEach(f => {
                        this.getErrorDisplayName(f.validate(f.value, true) ? f.validate(f.value, true) : null, errorDisplayName);
                        if (!f.isValid())
                            isValid = false;
                    })
                )
            );

        if (isValid) {
            let expenseInput: ExpenseInput = new ExpenseInput();
            expenseInput.enterpriseId = this.userEid;
            expenseInput.expenseId = expenseView.id;
            expenseInput.expenseTypeCd = expenseView.code;
            expenseInput.periodEnd = expenseView.periodEnd;
            expenseInput.fields = ExpenseServiceMapper.mapExpenseViewToFileInput(expenseView);
            expenseInput.mealsClaimed = ExpenseServiceMapper.mapMealsClaimedToInput(expenseView);
            expenseInput.giftEntryDto = ExpenseServiceMapper.mapGiftToInput(expenseView);
            expenseInput.recalculate = true;
            expenseInput.triggeredSource = ExpenseViewSource.Copy;
            expenseInput.isNotHotelFolioOrIsTheOnlyHotelFolio = expenseView.amexInfo
                ? expenseView.amexInfo.shouldImportWithTransaction
                : null;
            expenseInput.amexInput = expenseView.amexInfo
                ? ExpenseServiceMapper.mapSaveAmexInput(expenseView.amexInfo)
                : null;
            expenseInput.expenseImportInput = expenseView.importInfo
                ? this.useCorporateCard && ExpenseServiceMapper.mapSaveCorporateCardInput(expenseView.importInfo)
                : null;
            expenseInput.receipts = ExpenseServiceMapper.mapReceiptsToReceiptInput(expenseView, this.userEid);
            expenseInput.countryKey = countryKey;

            let receiptView = expenseView.categories.map(expenseRow => expenseRow)
                .reduce((prev, curr) => prev.concat(curr))
                .map(expenseViewRow => expenseViewRow.elements)
                .reduce((prev, curr) => prev.concat(curr))
                .find(expenseViewFieldRow => expenseViewFieldRow instanceof ReceiptViewField) as ReceiptViewField;

            let expenseReceipt = new ExpenseReceipts();
            if (receiptView != null) {
                expenseReceipt.receipts = receiptView.value;
                expenseReceipt.warningMessages = receiptView.warningMessages;
                expenseReceipt.missingReceiptInfo = receiptView.missingReceiptInfo;
            }

            expenseInput.missingReceiptInfo = receiptView.missingReceiptInfo;

            if (expenseView.softWarning)
                expenseInput.maximunAmountWarningConfirmation = expenseView.softWarning.userConfirmation;
            let saveExpenseRequest = this.api.copyExpense(this.supervisorEid, this.viewMode, expenseInput)
                .pipe(
                    map(expenseOutput => {
                        expenseOutput?.messages?.forEach(error => {
                            if (error?.text?.indexOf("The photo image is not clear.") != -1) {
                                this.logService.logError(`Unable to upload ${error?.type}. ${error?.text}`, true, 'Error On Saving Expense');
                            }
                        })
                        return ExpenseServiceMapper.mapExpenseView(expenseOutput, expenseReceipt)
                    })
                )
                .pipe(
                    tap(expenseOutput => this.globalCacheService.clearExpenseList(expenseOutput.periodEnd, countryKey))
                )
                .pipe(catchError(err => {
                    this.logService.logError(err, true, 'Error On Saving Expense');
                    return of(null);
                }));

            return this.globalCacheService.handleSaveExpense(expenseInput.periodEnd, expenseInput.enterpriseId, saveExpenseRequest, countryKey, expenseInput.expenseTypeCd);

        } else {
            if (errorDisplayName.length > 0) {
                let fields = new Array<any>();
                if (errorDisplayName.length === 1) {
                    this.logService.logWarning('Please complete: ' + errorDisplayName[0].label + ' field.', true, 'The Expense is not ready to save.');
                }
                if (errorDisplayName.length >= 2) {
                    errorDisplayName.forEach(element => {
                        fields.push(element.label);
                    });
                    this.logService.logWarning('Please complete: ' + fields.join(", ").replace(/, ([^,]*)$/, ' and $1') + ' fields.', true, 'The Expense is not ready to save.');
                }
            } else {
                this.logService.logWarning('Please complete all the required fields', true, 'The Expense is not valid.');
            }
            return of(null);
        }
    }

    public deleteExpense(expenseIds: string[], periodEnd: Date, countryKey: string): Observable<any> {
        let isDeleted = this.api.deleteExpenses(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, expenseIds.map(e => e.toString()))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
        this.globalCacheService.handleResetExpenses(periodEnd, countryKey);
        return isDeleted;
    }

    public reverseExpense(expenseIds: string[], periodEnd: Date): Observable<any> {
        let expenseReverseInput: ExpenseReverseInput = new ExpenseReverseInput();
        expenseReverseInput.expenseIds = expenseIds.map(e => e.toString());
        expenseReverseInput.enterpriseId = this.userEid;

        let isReversed = this.api.reverseExpenses(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, expenseReverseInput)
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
        return isReversed;
    }

    public reverseExpenseStore(expenseIds: string[], periodEnd: Date): Observable<boolean> {
        let expenseReverseInput: ExpenseReverseInput = new ExpenseReverseInput();
        expenseReverseInput.expenseIds = expenseIds.map(e => e.toString());
        expenseReverseInput.enterpriseId = this.userEid;

        let isReversed = this.api.reverseExpenses(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, expenseReverseInput)
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
        return isReversed;
    }

    public importAmexExpense(dataForImport: AmexInput, countryKey: string): Observable<ExpenseView> {

        let importAmexExpense = new ImportAmexInput();
        importAmexExpense.transactionId = dataForImport.transactionId;
        importAmexExpense.amexHotelImportDataId = dataForImport.amexHotelImportDataId;
        importAmexExpense.amountDisplay = dataForImport.amountDisplay;
        importAmexExpense.dateDisplay = dataForImport.dateDisplay;
        importAmexExpense.amexExpenseType = dataForImport.amexExpenseType;
        importAmexExpense.dateDisplayToDateWithDayOfWeek = dataForImport.dateDisplayToDateWithDayOfWeek;
        importAmexExpense.expenseSelectedKey = dataForImport.expenseSelectedKey;
        importAmexExpense.expenseSelectedValue = dataForImport.expenseSelectedValue;
        importAmexExpense.expenseTypeCodeDisplay = dataForImport.expenseTypeCodeDisplay;
        importAmexExpense.localCountryDisplay = dataForImport.localCountryDisplay;
        importAmexExpense.localCurrencyDisplay = dataForImport.localCurrencyDisplay;
        importAmexExpense.periodEnd = new Date(dataForImport.periodEnd);
        importAmexExpense.detailDisplay = dataForImport.detailDisplay;
        importAmexExpense.dateFrom = dataForImport.dateFrom;
        importAmexExpense.stateProvinceCd = null;
        importAmexExpense.countryKey = countryKey;

        return this.api.importAmexExpenses(this.userEid, this.supervisorEid, this.viewMode, importAmexExpense)
            .pipe(map(expenseOutput => {
                const outputExpense = ExpenseServiceMapper.mapExpenseView(expenseOutput);
                outputExpense.amexInfo = ExpenseServiceMapper.mapAmexInfo(expenseOutput.amexInfo);
                outputExpense.amexInfo.amexHotelImportDataId = expenseOutput.amexInfo.amexHotelImportDataId;
                outputExpense.expenseSource = ExpenseViewSource.AmexImport;
                return outputExpense;
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    public importCorporateCardExpense(expenseDataToImport: CorporateCardExpenseInput, countryKey: string): Observable<ExpenseView> {

        let expenseImportInput = new ExpenseImportInput();
        expenseImportInput.periodEnd = new Date(expenseDataToImport.periodEnd);
        expenseImportInput.transactionId = expenseDataToImport.transactionId;
        expenseImportInput.amexHotelImportDataId = expenseDataToImport.amexHotelImportDataId;
        expenseImportInput.chargeTypeCd = expenseDataToImport.chargeTypecd;
        expenseImportInput.dateDisplay = expenseDataToImport.dateDisplay;
        expenseImportInput.dateFrom = expenseDataToImport.dateFrom;
        expenseImportInput.expenseTypeCodeDisplay = expenseDataToImport.expenseTypeCodeDisplay;
        expenseImportInput.detailDisplay = expenseDataToImport.detailDisplay;
        expenseImportInput.amountDisplay = expenseDataToImport.amountDisplay;
        expenseImportInput.localCurrencyDisplay = expenseDataToImport.localCurrencyDisplay;
        expenseImportInput.localCountryDisplay = expenseDataToImport.localCountryDisplay;
        expenseImportInput.amexExpenseType = expenseDataToImport.amexExpenseType;
        expenseImportInput.dateDisplayToDateWithDayOfWeek = expenseDataToImport.dateDisplayToDateWithDayOfWeek;
        expenseImportInput.expenseSelectedValue = expenseDataToImport.expenseSelectedValue;
        expenseImportInput.expenseSelectedKey = expenseDataToImport.expenseSelectedKey;
        expenseImportInput.stateProvinceCd = expenseDataToImport.stateProvinceCd;
        expenseImportInput.countryKey = countryKey;

        return this.api.importCorporateCardExpense(this.userEid, this.supervisorEid, this.viewMode, expenseImportInput)
            .pipe(map(expenseOutput => {
                const expOutput = ExpenseServiceMapper.mapExpenseView(expenseOutput);
                expOutput.importInfo = ExpenseServiceMapper.mapImportInfo(expenseOutput.importInfo);
                expOutput.importInfo.amexHotelImportDataId = expenseOutput.importInfo.amexHotelImportDataId;
                expOutput.importInfo.chargeTypecd = expenseOutput.importInfo.chargeTypeCd;
                expOutput.expenseSource = ExpenseViewSource.AmexImport;

                return expOutput;
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    public deleteAmexExpenses(deleteAmexExpenseInfo: DeleteAmexExpenseInfo[]): Observable<boolean> {
        let inputValues: DeleteAmexExpenseInput[] = [];

        deleteAmexExpenseInfo.forEach(e => {
            let inputValue = new DeleteAmexExpenseInput();
            inputValue.enterpriseId = this.userEid;
            inputValue.transactionId = e.transactionId;
            inputValue.hotelImportedDataId = e.hotelImportedDataId;
            inputValue.shouldDeleteWithTransaction = e.shouldDeleteWithTransaction;
            inputValues.push(inputValue);
        });

        return this.api.deleteAmexExpenses(this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, inputValues)
            .pipe(map(response => ExpenseServiceMapper.mapRequestRes(response)))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    public DeleteCorporateCardExpenses(deleteCorporateCardInfo: DeleteCorporateCardInfo[]): Observable<boolean> {
        let inputValues: DeleteExpenseImport[] = [];

        deleteCorporateCardInfo.forEach(e => {
            let inputValue = new DeleteExpenseImport();
            inputValue.expenseImportId = e.expenseImportId;
            inputValue.enterpriseId = e.enterpriseId;
            inputValues.push(inputValue);
        });

        return this.api.deleteCorporateCardExpense(this.periodEndService.getActivePeriod(), this.userEid, this.viewMode, inputValues)
            .pipe(map(response => ExpenseServiceMapper.mapRequestRes(response)))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    };

    public checkIfPilot(eid: string): Observable<MyteTabControl> {
        const getSubordinateTabPermissionsObservable = this.api.isPilotUser(eid, this.periodEndService.getActivePeriod(), undefined, undefined)
            .pipe(map((myteTabControlOutput: MyteTabControlOutput) => {
                return SubordinatesServiceMapper.mapAPIMyteTabControlOutput(myteTabControlOutput);
            }))
            .pipe(catchError((error: any) => {
                this.logService.logError(error, true);
                return of(undefined);
            }));
        return this.globalCacheService.handleMyteTabControl(getSubordinateTabPermissionsObservable, eid);
    }
    public removeExpenseListCache(periodEndList: Date[]): Observable<void> {
        return this.api.removeExpenseListCache(this.userEid, null, null, periodEndList);
    }

    public getIsFromExpensePopupCloseWithoutAmex(): boolean {
        return this.isFromExpensePopupCloseWithoutAmex;
    }

    public setIsFromExpensePopupCloseWithoutAmex(flag: boolean): void {
        this.isFromExpensePopupCloseWithoutAmex = flag;
    }

    public changeInMissingReceiptField() {
        this.saveChange.next(true);
    }


    public mapExpenseViewToInputStore(expenseView: ExpenseView, receiptView: ReceiptViewField, countryKey: string, triggeredBy: ExpenseViewRowElement = null) {
        // This mapping has been copied from regular SaveExpense as well the receiptView mapping
        let expenseInput: ExpenseInput = new ExpenseInput();
        let expense = this.expenses.find(expense => expense.id === expenseView.id);
        expenseInput.enterpriseId = this.userEid;
        expenseInput.expenseId = expenseView.id;
        expenseInput.expenseTypeCd = expenseView.code;
        expenseInput.periodEnd = expenseView.periodEnd;
        expenseInput.fields = ExpenseServiceMapper.mapExpenseViewToFileInput(expenseView);
        expenseInput.mealsClaimed = ExpenseServiceMapper.mapMealsClaimedToInput(expenseView);
        expenseInput.giftEntryDto = ExpenseServiceMapper.mapGiftToInput(expenseView);
        expenseInput.recalculate = true;
        expenseInput.isNotHotelFolioOrIsTheOnlyHotelFolio = expenseView.amexInfo ? expenseView.amexInfo.shouldImportWithTransaction : null;
        expenseInput.amexInput = expenseView.amexInfo ? ExpenseServiceMapper.mapSaveAmexInput(expenseView.amexInfo) : null;
        expenseInput.expenseImportInput = expenseView.importInfo ? this.useCorporateCard && ExpenseServiceMapper.mapSaveCorporateCardInput(expenseView.importInfo) : null;
        expenseInput.triggeredBy = triggeredBy?.key;
        expenseInput.hasIssues = expense?.hasissues;
        expenseInput.sequenceNm = Number(expense?.sequence.value);
        if(expenseInput.triggeredBy)
            expenseInput.receipts = [];
        else
            expenseInput.receipts = ExpenseServiceMapper.mapReceiptsToReceiptInput(expenseView, this.userEid);

        expenseInput.countryKey = countryKey;
        expenseInput.missingReceiptInfo = receiptView.missingReceiptInfo;
        expenseInput.triggeredSource = expenseView.expenseSource;
        let expenseReceipt = new ExpenseReceipts();
        if (receiptView != null) {
            expenseReceipt.receipts = receiptView.value;
            expenseReceipt.warningMessages = receiptView.warningMessages;
        }


        if (expenseView.softWarning)
            expenseInput.maximunAmountWarningConfirmation = expenseView.softWarning.userConfirmation;
        return { expenseInput, expenseReceipt };
    }

    public saveExpenseStore(expenseView: ExpenseView, countryKey: string, receiptView: ReceiptViewField) {
        let { expenseInput, expenseReceipt } = this.mapExpenseViewToInputStore(expenseView, receiptView, countryKey)

        return this.api.saveExpense(this.supervisorEid, this.viewMode, expenseInput)
            .pipe(map(expenseOutput => {
                let toastMsg = [];
                expenseOutput?.fields?.forEach(f => {
                    f?.validations?.forEach(v => {
                        if (v && v.type == 'saveValiatorError' || v.type == 'BlockError') {
                            if (toastMsg.indexOf(v.value) === -1) {
                                toastMsg.push(v.value);
                            }
                        }
                    })
                });
                if (toastMsg && toastMsg.length > 0) toastMsg.forEach(message => this.logService.logError(message, true, ""));
                expenseOutput?.messages?.forEach(error => {
                    if (error?.text?.indexOf("The photo image is not clear.") != -1) {
                        this.logService.logError(`Unable to upload ${error?.type}. ${error?.text}`, true, 'Error On Saving Expense');
                    }
                });
                let response = new SaveExpenseResponse(ExpenseServiceMapper.mapExpenseView(expenseOutput, expenseReceipt));

                if (response.expenseView.expenseSource !== expenseView.expenseSource)
                    response.expenseView.expenseSource = expenseView.expenseSource;

                if (expenseView.amexInfo != null && response.expenseView.amexInfo == null) {
                    response.expenseView.amexInfo = expenseView.amexInfo;
                }

                response.timeReportStatus = expenseOutput.timeReportStatus;
                response.passed = true;
                this.globalCacheService.clearTimeReportSummary(expenseView.periodEnd, this.userEid);
                return response;
            })).pipe(catchError(err => {
                this.logService.logError(err, true, 'Error On Saving Expense');
                let response = new SaveExpenseResponse(expenseView);
                response.passed = false;
                return of(response);
            }));
    }

    public recalculateExpenseStore(expenseView: ExpenseView, receiptView: ReceiptViewField, triggeredBy: ExpenseViewRowElement, countryKey: string): Observable<SaveExpenseResponse> {
        let { expenseInput, expenseReceipt } = this.mapExpenseViewToInputStore(expenseView, receiptView, countryKey, triggeredBy);

        return this.api.expenseDetails(this.supervisorEid, this.viewMode, expenseInput)
            .pipe(map(expenseOutput => ExpenseServiceMapper.mapExpenseView(expenseOutput, expenseReceipt)))
            .pipe(map(newExpenseView => ExpenseServiceMapper.joinRecalculatedExpense(newExpenseView, expenseView)))
            .pipe(map(expenseView => {
                let response = new SaveExpenseResponse(expenseView);
                response.passed = true;
                return response;
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                let response = new SaveExpenseResponse(expenseView);
                response.passed = false;
                return of(response);
            }));
    }

    public findAmexExpensesForAnyStore(periodEnd: Date, countryKey: string, isAutoShowAmexExpense: boolean = false): Observable<any> {
        return this.api.getAmexExpenses(this.userEid, periodEnd, countryKey, isAutoShowAmexExpense, this.supervisorEid, this.viewMode, "Next")
            .pipe(res => {
                return res;
            })
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    public findCorporateCardExpensesStore(periodEnd: Date, countryKey: string, isAutoShowAmexExpense: boolean = false): Observable<any> {
        return this.api.getCorporateCardExpense(this.userEid, periodEnd, countryKey, isAutoShowAmexExpense, this.supervisorEid, this.viewMode)
            .pipe(res => {
                return res;
            })
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }

    public deleteExpenseStore(expenseIds: string[], periodEnd: Date, countryKey: string): Observable<boolean> {
        let isDeleted = this.api.deleteExpenses(this.userEid, this.periodEndService.getActivePeriod(), this.supervisorEid, this.viewMode, expenseIds)
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
        this.globalCacheService.handleResetExpenses(periodEnd, countryKey);
        this.globalCacheService.clearTimeReportSummary(periodEnd, this.userEid);
        return isDeleted;
    }

    public copyExpenseStore(expenseView: ExpenseView, receiptView: ReceiptViewField, countryKey: string): Observable<SaveExpenseResponse> {
        let { expenseInput, expenseReceipt } = this.mapExpenseViewToInputStore(expenseView, receiptView, countryKey);
        let request = this.api.copyExpense(this.supervisorEid, this.viewMode, expenseInput)
            .pipe(map(expenseOutput => {
                let response = new SaveExpenseResponse(ExpenseServiceMapper.mapExpenseView(expenseOutput, expenseReceipt));
                response.passed = true;
                response.timeReportStatus = expenseOutput.timeReportStatus;
                expenseOutput?.messages?.forEach(error => {
                    if (error?.text?.indexOf("The photo image is not clear.") != -1) {
                        this.logService.logError(`Unable to upload ${error?.type}. ${error?.text}`, true, 'Error On Saving Expense');
                        response.passed = false;
                    }
                })
                return response;
            })
            )
            .pipe(catchError(err => {
                let response = new SaveExpenseResponse(expenseView);
                response.passed = false;
                this.logService.logError(err, true, 'Error On Saving Expense');
                return of(response);
            }));
        this.globalCacheService.handleCopyExpense(expenseView.periodEnd, request, countryKey);
        this.globalCacheService.clearTimeReportSummary(expenseView.periodEnd, this.userEid);
        return request;
    }

    public getExpensesStore(endPeriod: Date, countryKey: string, submissionMode?: TimeReportSubmitMode): Observable<ExpensesResponse> {
        let parameters: ExpenseListInput = new ExpenseListInput();
        let supervisorEid = this.supervisorEid;
        let userEid = this.userEid;
        if (this.globalCacheService.getSubordinateMode() == "ReviewEmail") {
            userEid = this.globalCacheService.getRevieweeEid();
        }
        else if (this.isSubordinatedMode && this.globalCacheService.getCurrentSubordinate() !== null && userEid != this.globalCacheService.getCurrentSubordinate().enterpriseId) {
            userEid = this.globalCacheService.getCurrentSubordinate().enterpriseId;
            supervisorEid = this.userEid;
        }
        parameters.enterpriseId = userEid;
        parameters.periodEnd = endPeriod;
        parameters.countryKey = countryKey;
        parameters.submissionMode = submissionMode;
        let requestExpenses = this.api.expenseList(supervisorEid, this.viewMode, parameters)
            .pipe(map(expenseOutput => {
                let expensesResponse = new ExpensesResponse();
                expensesResponse.expenses = ExpenseServiceMapper.mapExpenses(expenseOutput.expenses, expenseOutput.timeReportStatus, expenseOutput.expenseTotalError);
                expensesResponse.timeReportStatus = expenseOutput.timeReportStatus;
                let isExpenseEditableForAudit = expensesResponse.expenses.some(item => item.isEditableForAuditor === true);
                this.globalCacheService.setDataToSessionStorage(this.globalCacheService.expenseEditableForAudit, isExpenseEditableForAudit);
                this.globalCacheService.setExpenseTotalError(expenseOutput.expenseTotalError, endPeriod);
                this.clearTimesheetCacheForIllinois(parameters.enterpriseId, expenseOutput.timeReportStatus);
                this.initializeTabsDictionary(expensesResponse.expenses);
                return expensesResponse;
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(new ExpensesResponse());
            }));
        this.globalCacheService.getTabs(endPeriod)
            .subscribe(tabs => this.dictionaryTabs = tabs);
        return this.globalCacheService.handleExpenseList(endPeriod, countryKey, requestExpenses);
    }

    //created service method to get the options for myte-dropdown-search dropdown for Sustainability fields
    public getSearchDropdownDetailByPrefix(prefix: string, expenseType: string): Observable<DropdownItem[]> {
        return this.api.getSearchDropdownDetailByPrefix(prefix, expenseType)
            .pipe(map((dropdownItems: { [key: string]: string; }) => {
                return ExpenseServiceMapper.mapAPIDropdownItems(Object.entries(dropdownItems));
            }))
            .pipe(catchError(err => {
                this.logService.logError(err, true);
                return of(null);
            }));
    }
}
