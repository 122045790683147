import { PunchClockConfiguration } from './punch-clock-configuration';
import { PunchClockEntry } from './punch-clock-entry';
import { PunchClockMealBreakReasonConfiguration } from './punch-clock-meal-break-reason-configuration';
import * as moment from 'moment';
import { PunchClockTimeEntry } from './punch-clock-time-entry';
import { COUNTRIES_WITH_ZERO_MINUTES } from '../../../../myte-time/components/myte-time-popups/shift-schedule-popup/constants/description-types.constant';
import { MealEndTime } from '../../../clients/time-api-client';
import { PunchClockChargeCodeConfiguration } from './punch-clock-charge-code-configuration';

export class PunchClock {
  public configuration: PunchClockConfiguration;
  public editable: boolean;
  public isReadOnly : boolean;
  public entries: PunchClockEntry[];
  public message: string;
  public isClearAllButtonEnabled: boolean;
  private isRemovable = 'remove';
  public defaultEntryTime: PunchClockTimeEntry;
  public mealBreakReasonConfiguration: PunchClockMealBreakReasonConfiguration;
  public chargeCodeConfiguration: PunchClockChargeCodeConfiguration;

  constructor() {
  }

  public initializeGridConfiguration() {
    [...new Set(this.entries.map(entry => entry.dateTime.getDate()))].forEach(date => {
      let entries = this.entries.filter(entry => entry.dateTime.getDate() == date);
      const firstItem = Math.min.apply(Math, entries.map(entry => entry.sequenceNumber));
      const lastItem = Math.max.apply(Math, entries.map(entry => entry.sequenceNumber));

      this.defaultEntryTime = {
        hours: null,
        minutes: COUNTRIES_WITH_ZERO_MINUTES.includes(this.configuration.countryCd) ? 0 : null
      }

      entries.forEach(entry => {
        entry.gridConfiguration = {
          isFirst: entry.sequenceNumber == firstItem,
          actions: [],
          rowSpan: 1
        };
        if (entry.gridConfiguration.isFirst) {
          entry.gridConfiguration.rowSpan = entries.length;
        }

        if (this.configuration.displayAddRowButton && entry.gridConfiguration.isFirst) {
          entry.gridConfiguration.actions.push('add');
        }

        if (this.configuration.displayRemoveRowButton && entry.sequenceNumber != firstItem) {
          entry.gridConfiguration.actions.push(this.isRemovable);
        }

        if (this.configuration.displayBreakDuration) {
          entry.gridConfiguration.breakDurationList = this.breakDurationList(this.configuration.countryCd);
        }

        if (this.mealBreakReasonConfiguration.shouldDisplayColumn){
          let current = this.mealBreakReasonConfiguration.dateInfo.find(dateInfo => (dateInfo.date.getDate() === entry.dateTime.getDate() && dateInfo.sequenceNbr == entry.sequenceNumber)) ;
          if(current){
            entry.mealBreakReason = current.content;
          }
        }

        if (this.chargeCodeConfiguration.shouldDisplayColumn){
          let current = this.chargeCodeConfiguration.chargeCodeDateInfo.find(dateInfo => (dateInfo.date.getDate() === entry.dateTime.getDate() && dateInfo.sequenceNbr == entry.sequenceNumber)) ;
          if(current){
            entry.wbsExternalNbr = current.selectedChargeCode
          }
        }
        entry.gridConfiguration.timeList = this.timeList;
      });
    });
  }

  public isIncompleteEntry(punchClockEntry: PunchClockEntry): boolean {
    return (punchClockEntry != undefined) && (!punchClockEntry.workStartTime ||
    punchClockEntry.workStartTime.hours === null || punchClockEntry.workStartTime.hours === -1 ||
    punchClockEntry.workStartTime.minutes === null || punchClockEntry.workStartTime.minutes === -1 ||
    !punchClockEntry.workEndTime ||
    punchClockEntry.workEndTime.hours === null || punchClockEntry.workEndTime.hours === -1 || 
    punchClockEntry.workEndTime.minutes === null || punchClockEntry.workEndTime.minutes === -1)
  }


  public isIncompleteEntryForAT(punchClockEntry: PunchClockEntry): boolean {
    if (punchClockEntry.sequenceNumber == 1)
      return this.isIncompleteEntryForFirstRow(punchClockEntry);
    else
      return this.isIncompleteEntryForFirstRow(punchClockEntry) &&
      (!punchClockEntry.mealStartTime || punchClockEntry.mealStartTime.hours === null || punchClockEntry.mealStartTime.hours === -1 ||
      punchClockEntry.mealStartTime.minutes === null || punchClockEntry.mealStartTime.minutes === -1  || 
      !punchClockEntry.mealEndTime || punchClockEntry.mealEndTime.hours === null || punchClockEntry.mealEndTime.hours === -1 ||
      punchClockEntry.mealEndTime.minutes === null || punchClockEntry.mealEndTime.minutes === -1 || 
      !(punchClockEntry.workStartTime.hours === null &&  punchClockEntry.workEndTime.hours === null));
  }
  private isIncompleteEntryForFirstRow(punchClockEntry: PunchClockEntry): boolean
  { return (punchClockEntry != undefined) && (!punchClockEntry.workStartTime ||
     punchClockEntry.workStartTime.hours === null || punchClockEntry.workStartTime.hours === -1 ||
     punchClockEntry.workStartTime.minutes === null || punchClockEntry.workStartTime.minutes === -1 ||
     !punchClockEntry.workEndTime || punchClockEntry.workEndTime.hours === null || punchClockEntry.workEndTime.hours === -1 || 
     punchClockEntry.workEndTime.minutes === null || punchClockEntry.workEndTime.minutes === -1 || 
     !(punchClockEntry.mealStartTime.hours === null &&  punchClockEntry.mealEndTime.hours === null)) &&
     (!punchClockEntry.workStartTime || punchClockEntry.workStartTime.hours === null || punchClockEntry.workStartTime.hours === -1 ||
     punchClockEntry.workStartTime.minutes === null || punchClockEntry.workStartTime.minutes === -1 ||
     !punchClockEntry.workEndTime || punchClockEntry.workEndTime.hours === null || punchClockEntry.workEndTime.hours === -1 || 
     punchClockEntry.workEndTime.minutes === null || punchClockEntry.workEndTime.minutes === -1 || !punchClockEntry.mealStartTime 
     || punchClockEntry.mealStartTime.hours === null || punchClockEntry.mealStartTime.hours === -1 ||
     punchClockEntry.mealStartTime.minutes === null || punchClockEntry.mealStartTime.minutes === -1  || 
     !punchClockEntry.mealEndTime || punchClockEntry.mealEndTime.hours === null || punchClockEntry.mealEndTime.hours === -1 ||
     punchClockEntry.mealEndTime.minutes === null || punchClockEntry.mealEndTime.minutes === -1)
  } 



  public isIncompleteEntryForCopy(punchClockEntry: PunchClockEntry): boolean {
    let workTimeHasEmpty = this.isIncompleteEntry(punchClockEntry);
    let breakDurationIsEmpty = false;
    let mealTimeHasEmpty = false;

    if (this.configuration.displayBreakDuration)
    {
      breakDurationIsEmpty = punchClockEntry == undefined || punchClockEntry.breakDuration === null || punchClockEntry.breakDuration === -1;
    }

    if (this.configuration.displayMealTime)
    {
      mealTimeHasEmpty = punchClockEntry == undefined || !punchClockEntry.mealStartTime ||
      punchClockEntry.mealStartTime.hours === null || punchClockEntry.mealStartTime.hours === -1 ||
      punchClockEntry.mealStartTime.minutes === null || punchClockEntry.mealStartTime.minutes === -1 ||
      !punchClockEntry.mealEndTime ||
      punchClockEntry.mealEndTime.hours === null || punchClockEntry.mealEndTime.hours === -1 ||
      punchClockEntry.mealEndTime.minutes === null || punchClockEntry.mealEndTime.minutes === -1;
    }

    return workTimeHasEmpty || breakDurationIsEmpty || mealTimeHasEmpty;
  }

  public isEmptyEntry(punchClockEntry: PunchClockEntry): boolean {
    return (punchClockEntry.workStartTime.hours === this.defaultEntryTime.hours || punchClockEntry.workStartTime.hours === -1) &&
      (punchClockEntry.workStartTime.minutes === this.defaultEntryTime.minutes || punchClockEntry.workStartTime.minutes === -1) &&
      (punchClockEntry.workEndTime.hours === this.defaultEntryTime.hours || punchClockEntry.workEndTime.hours === -1) &&
      (punchClockEntry.workEndTime.minutes === this.defaultEntryTime.minutes || punchClockEntry.workEndTime.minutes === -1) &&
      (punchClockEntry.mealBreakReason === null || punchClockEntry.mealBreakReason === undefined) &&
      (punchClockEntry.wbsExternalNbr === null || punchClockEntry.wbsExternalNbr === undefined);
  }

  public getLastCharge(punchClockEntry: PunchClockEntry): PunchClockEntry {
    let entries = this.entries.filter(entry => entry.dateTime.getDate() ===  punchClockEntry.dateTime.getDate());
    return entries[entries.length - 1];
  }

  public validateBreakDuration(): boolean {
    let valid = true;
    this.entries.forEach(entry => {
      if (entry.workStartTime && entry.workStartTime.hours && entry.breakDuration) {
        let start = moment.duration({
          hours: entry.workStartTime.hours,
          minutes: entry.workStartTime.minutes
        });
        let end = moment.duration({
          hours: entry.workEndTime.hours,
          minutes: entry.workEndTime.minutes
        });
        let workDuration: number = moment
          .duration(end.subtract(start))
          .asMinutes();
        let breakDur = moment
          .duration(entry.breakDuration * 60, 'minutes')
          .asMinutes();
        entry.hasError = Math.abs(workDuration) < breakDur;
        if (entry.hasError) valid = false;
      }
    });
    return valid;
  }

  public validateIncompleteEntries(): boolean {
    let valid = true;
    this.entries.forEach(entry => {
      entry.hasError = !this.isEmptyEntry(entry) && this.isIncompleteEntry(entry);
      if (entry.hasError) valid = false;
    });
    return valid;
  }
  private get timeList() {
    let meridianHourList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return {
      hours: this.configuration.use24HsFormat ? this.calculateTimeList(24 , 1) : meridianHourList,
      minutes: this.calculateTimeList(60, this.configuration.minutesIncrement)
    };
  }

  private breakDurationList(countryCd: string): number[] {
    return countryCd == 'IE' || countryCd == 'PL' || countryCd == 'AU' || countryCd == 'MY' || countryCd == 'CZ' ? this.calculateTimeList(24.25, 0.25) : 
          countryCd == 'TH' ? this.calculateTimeList(24+1/6, 1/6) : this.calculateTimeList(24.5, 0.5);
  }

  private calculateTimeList(lenght: number, increment: number) {
      let roundedValue = Math.round((lenght/increment + Number.EPSILON)*100)/100;
      return new Array(roundedValue).fill(null).map((value, index) =>index * increment );
  }
}
