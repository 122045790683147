<button type="button"
  class="icon-container accessibility-div" id="Previous_Period_Button" tabindex="0" aria-label="Navigate to Previous Period"
  [focus]=focusPreviousPeriod
  (click)="previousPeriod()">
  <mat-icon aria-hidden="true" fontIcon="keyboard_arrow_left"></mat-icon>
</button>
<div class="input-group periodDropdown">
  <myte-dropdown [elementsList]="dropdownDates" [selectedItem]="currentDate?.toDropdownItem()" [focusDropdown]="focusDropdown" [height]="27" [hasdatevalue]="true"
    (updateValueEvent)="onDateSelectedFromDropdown($event,true)" label="{{ 'Selected Period ' + currentDate?.toDropdownItem().value }}" [ariaLabel]="arialabel" dropdownId="select-period-dropdown"></myte-dropdown>
    <div class="input-group">
			<input class="form-control" type="hidden" style="display: none" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
        (dateSelect)="onDateSelectFromCalendar($event)" [(ngModel)]='DateDropDown' [footerTemplate]="footerTemplate" [positionTarget]="buttonEl"
				#d="ngbDatepicker" [firstDayOfWeek]="7" aria-label="Hidden Calendar Date" [dayTemplate]="customDay"/>
			<button #buttonEl class="btn btn-sm btn-submit calendar" (click)="d.toggle()" type="button" aria-label="Change Time-Period Date Calendar" [focus]="focusCalendar">
        <mat-icon class="material-icons" aria-hidden="true" fontIcon="today"></mat-icon>
      </button>
		</div>
</div>
<ng-template #footerTemplate>
	<hr class="my-0" />
	<button class="accessibility-div m-1 float-start footer-style" (click)="onClickToday()">{{stringToday}}</button>
</ng-template>
<ng-template  #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused" >
  <div class="custom-day-datePicker" [class.focused]="focused" [class.custom-day-datePicker-disabled]="isDisabled(date, currentMonth)" [class.custom-day-datePicker-selected]="selected" ngbTooltip= "{{date | dateformat:'GetDateForTooltip'}}">{{date.day}}</div>
</ng-template>
<button type="button"
  class="icon-container accessibility-div" id="Next_Period_Button" tabindex="0" aria-label="Navigate to Next Period"
  [focus]=focusNextPeriod
  (click)="nextPeriod()">
  <mat-icon aria-hidden="true" fontIcon="keyboard_arrow_right"></mat-icon>
</button>
